import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Kontakt from "../components/Layout/Contact";
import CarouselReference from "../components/Components/CarouselReference";

export default function Spravait() {
    return (
        <Layout>
            <div className="headerBluePic mb-5">
                <div className="headerBlue_content">
                    <div className="container">
                        <div className="row pt-4">
                            <div className="col-12 col-lg-6 pt-5">
                                <h1 className="heading-border-bottom-white">Správa IT</h1>
                                <h2 className="pt-1">Jsme vaše<br />externí IT oddělení</h2>
                                <p className="subHeadline pt-1">Staráme se o IT pro desítky malých a středně velkých
                                    firem a organizací. Za 15 let naší praxe se tomu říkalo různě: IT outsourcing,
                                    správa IT, nebo správa sítě.</p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mx-auto pt-4 p-xxl-5">
                                    <StaticImage imgClassName={"it headerBlue_img"}
                                                 alt={"Správa IT"}
                                        src="../assets/custom-style/images/ilustrace-01.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="subHeader pt-5 pb-5 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="100" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"it"} src="../assets/custom-style/images/icons/clock.webp" alt={"ikona - čas"} />
                            </div>
                            <p className="pt-3">Pracujte efektivně <br className="d-md-inline d-none" /> díky <br className="d-inline d-md-none" />skvěle <br className="d-md-inline d-none" />vyladěnému IT</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"device"}
                                    src="../assets/custom-style/images/icons/computer.webp" alt={"ikona - zařízení"}/>
                            </div>
                            <p className="pt-3">Využijte pro práci <br className="d-md-inline d-none" /> zařízení, <br className="d-inline d-md-none" />které vám <br className="d-md-inline d-none" />vyhovují</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"communication"}
                                    src="../assets/custom-style/images/icons/communication.webp" alt={"ikona - komunikace"} />
                            </div>
                            <p className="pt-3">Zjednodušte <br className="d-md-inline d-none" />komunikaci <br className="d-inline d-md-none" />a&nbsp;přístup <br className="d-md-inline d-none" />k&nbsp;informacím</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="100" data-sal-easing="ease">
                            <div className="card-icon">
                                <StaticImage imgClassName={"protection"}
                                    src="../assets/custom-style/images/icons/protection.webp" alt={"ochrana"} />
                            </div>
                            <p className="pt-3">Chraňte svá data <br className="d-md-inline d-none" /><br className="d-inline d-md-none" />a&nbsp;mějte klidné <br className="d-md-inline d-none" />spaní</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bgGrey pt-5 pb-5">
                <div className="container">
                    <h2 className="text-center">Moderní IT je váš pomocník</h2>
                    <p className="text-center pt-5">Moderní IT je srozumitelné pro&nbsp;uživatele, funguje spolehlivě a&nbsp;hlavně Vám pomáhá se&nbsp;zpracováním a&nbsp;sdílením informací, <br className="d-none d-xl-inline" />v&nbsp;komunikaci, spolupráci a&nbsp;dalších
                        klíčových oblastech. A&nbsp;samozřejmě musí být dobře chráněné proti vnějším hrozbám.</p>
                    <div className="digiCards pt-5">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="500" data-sal-easing="ease">
                                <div className="card   h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"time"}
                                                         alt={"ikona - čas"}
                                                src="../assets/custom-style/images/icons/time.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Šetřete svým časem</h5>
                                        <p className="card-text"><span className="d-none d-xl-inline">Chcete být produktivnější bez ohledu na to, jestli jste
                                            v kanceláři, na cestách, nebo na schůzce? </span>Moderní IT vám umožní pracovat
                                            kdykoliv, odkudkoliv a z jakéhokoliv zařízení. <span className="d-none d-md-inline">Už nemusíte ztrácet čas
                                                hledáním toho správného zařízení a nástroje.</span></p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"team"} alt={"ikona - tým"}
                                                src="../assets/custom-style/images/icons/team.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Váš tým dokáže víc</h5>
                                        <p className="card-text"><span className="d-none d-xl-inline">Přáli byste si, aby spolupráce s kolegy a partnery,
                                            byla jednodušší, ať jsou kdekoliv? </span>Díky nástrojům, jako je Microsoft Teams
                                            můžete komunikovat a spolupracovat, jak se vám zlíbí. <span className="d-none d-md-inline">Chat, video call nebo
                                                prezentace nebyly nikdy jednodušší.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="100" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"office"} alt={"ikona - kancelář"}
                                                src="../assets/custom-style/images/icons/office.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Office vždy a všude</h5>
                                        <p className="card-text"><span className="d-none d-xl-inline">Už se vám stalo, že jste v kanceláři vytvořili dokument
                                            a teď ho rychle potřebujete? </span>S Office 365 máte přístup k vašim souborům na
                                            libovolném zařízení. <span className="d-none d-md-inline">Prostě vezmete první zařízení co je po ruce, spustíte a
                                                budete pokračovat, kde jste skončili.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"safety"}
                                                src="../assets/custom-style/images/icons/lock.webp" alt={"ikona - zabezpečení"} />
                                        </div>
                                        <h5 className="card-title pt-3">Mějte data v bezpečí</h5>
                                        <p className="card-text"><span className="d-none d-xl-inline">Potřebujete chránit svá cenná data a osobní údaje?
                                        </span>Využijte moderní způsoby, jako je například druhý faktor ověření či
                                            pokročilý filtr nevyžádané pošty. <span className="d-none d-md-inline">Naši IT odborníci vám se zabezpečením rádi
                                                pomůžou.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <div className="bgWhite pt-5 pb-5">
                <div className="container">
                    <h2 className="text-center">Správa IT ve firmě či organizaci</h2>
                    <p className="text-center pt-5">Klíčem k bezproblémovému fungování Vašeho IT je odborná péče a&nbsp;plánování. <br className="d-none d-lg-inline" />A protože to samé řeší mnoho firem, stačí využít již hotové a&nbsp;časem osvědčené
                        metodiky a&nbsp;směrnice.</p>
                    <div className="digiCards">
                        <div className="row pt-5">
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"helpdesk"}
                                                src="../assets/custom-style/images/icons/helpdesk.webp" alt={"ikona - pomoc"} />
                                        </div>
                                        <h5 className="card-title pt-3">Helpdesk připravenost</h5>
                                        <p className="card-text"><span className="d-none d-xl-inline">Potřebujete konzultaci? Nebo něco nefunguje? Nebo
                                            řešíte personální změny? </span>Na požadavky reagujeme do 60 minut od nahlášení a
                                            následně řešíme dle domluvené priority. Havarijní stavy řešíme okamžitě.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="100" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"catalog"} alt={"ikona - katalog"}
                                                src="../assets/custom-style/images/icons/catalog.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Katalog IT služeb</h5>
                                        <p className="card-text d-none d-xl-block">Je to jednodušší, než se zdá. IT službou se rozumí
                                            internet, emaily, sdílení souborů, účetní aplikace atd. Katalog IT služeb
                                            popisuje to, co uživatelé potřebují ke svojí práci. Katalog IT služeb je to,
                                            co musí fungovat.</p>
                                        <p className="card-text d-block d-xl-none">Vytvoříme Váš Katalog IT služeb, který popisuje, co uživatelé potřebují ke svojí práci. Katalog IT služeb je to, co musí fungovat.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"documentation"} alt={"ikona - dokumentace"}
                                                src="../assets/custom-style/images/icons/documentation.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Vedení dokumentace</h5>
                                        <p className="card-text d-none d-xl-block">Během naší práce vytváříme a udržujeme standardní
                                            dokumentaci. Mezi ní patří katalog IT služeb, seznam chráněných informací,
                                            evidence uživatelů, počítačů, software, zařízení, hesel a postupů.</p>
                                            <p className="card-text d-block d-xl-none">Během naší práce vytváříme a udržujeme standardní dokumentaci, díky které máte vždy komplexní přehled o IT Vaší společnosti.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="500" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"info"} alt={"ikona - informace"}
                                                src="../assets/custom-style/images/icons/info.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Info pro uživatele</h5>
                                        <p className="card-text d-none d-xl-block">Z praxe víme, že největší slabinou celého IT je
                                            uživatel, který něco neví. Proto máme zpracovanou komunikační kampaň, která
                                            pokrývá klíčové oblasti, jako je např. nástup do práce, bezpečnost a
                                            komunikace s podporou.</p>
                                            <p className="card-text d-block d-xl-none">Komunikujeme s Vašimi uživateli klíčová témata, jako je např. nástup do práce, bezpečnost a komunikace s podporou.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"skill"} alt={"ikona - skill"}
                                                src="../assets/custom-style/images/icons/skill.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Odbornost</h5>
                                        <p className="card-text d-none d-xl-block">Jsme firma, která je na trhu více než 15 let.
                                            Poskytneme vám zastupitelnost techniků, potřebnou odbornost, flexibilitu,
                                            pojištění odpovědnosti či dohodu o mlčenlivosti. Když porostete, službu vám
                                            přizpůsobíme.</p>
                                            <p className="card-text d-block d-xl-none">Jsme na trhu více než 15 let. Poskytneme vám vše, co Vaše firma potřebuje. Když porostete, službu vám přizpůsobíme.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="100" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"plan"} alt={"ikona - plán"}
                                                src="../assets/custom-style/images/icons/plan.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Havarijní plán</h5>
                                        <p className="card-text d-none d-xl-block">Pomůžeme vám s analýzou rizik, plánem zálohování a
                                            vytvoříme postup co dělat, když se něco pokazí. Havarijní plán se stane
                                            součástí dokumentace a díky němu budete mít klidné spaní.</p>
                                            <p className="card-text d-block d-xl-none">Pomůžeme vám s analýzou rizik, plánem zálohování a vytvoříme postup co dělat, když se něco pokazí.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"security"} alt={"ikona - bezpečnost"}
                                                src="../assets/custom-style/images/icons/security.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Zabezpečení počítačů</h5>
                                        <p className="card-text d-none d-xl-block">Základní pilíře bezpečnosti počítačů tvoří antivir,
                                            firewall a aktualizace. Jejich stav může sledovat buď sám uživatel, nebo to
                                            nechat na nás. Když se něco stane, budeme vás kontaktovat.</p>
                                            <p className="card-text d-block d-xl-none">Pohlídáme za vás bezpečnost počítačů, tedy antivir, firewall a aktualizace. Když se něco stane, budeme vás kontaktovat.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                                <div className="card  h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"quality"} alt={"ikona - kvalita"}
                                                src="../assets/custom-style/images/icons/quality.webp" />
                                        </div>
                                        <h5 className="card-title pt-3">Kontrola kvality</h5>
                                        <p className="card-text d-none d-xl-block">Jednou ročně zkontrolujeme katalog služeb, provedeme
                                            průzkum spokojenosti uživatelů, spojíme se s vámi a zkusíme posunout IT
                                            služby zase o kousek dál.</p>
                                            <p className="card-text d-block d-xl-none">Jednou ročně se s vámi spojíme, provedeme revizi Vašeho IT a zkusíme posunout IT služby zase o kousek dál.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <CarouselReference />
                </div>

            </div>
            <Kontakt />
        </Layout>
    );
}